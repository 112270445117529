// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {colors, mediaQueries} from '../theme';
import styles from '../theme/styles';
import {rhythm} from '../utils/typography';

import TextSmallCaps from './TextSmallCaps';

const StyledInputBlock = styled.label`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${rhythm(3 / 4)};

  small {
    color: ${colors.black60};
    margin-bottom: ${rhythm(1 / 4)};
    ${mediaQueries.MAX_SM} {
      font-size: 0.5rem;
    }
  }

  input {
    ${mediaQueries.MIN_LG} {
      max-width: 82%;
    }
  }

  textarea {
    resize: vertical;
  }

  input,
  textarea {
    padding: ${rhythm(4 / 9)} ${rhythm(2 / 5)};
    background-color: ${colors.offWhite};
    border: none;
    color: ${colors.textDefault};
    box-sizing: border-box;
    transition: ${styles.transitions.fastHover};

    &::placeholder {
      color: ${colors.black60};
    }

    &:hover {
      background-color: #f7f7f7;
      transition: ${styles.transitions.fastHover};
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: 0 0 0 1px ${colors.offWhite}, 0 0 0 2px ${colors.blackSoft};
      background-color: #fff;
      transition: ${styles.transitions.fastHover};
    }
  }

  ${mediaQueries.MIN_LG} {
    small {
      margin-bottom: ${rhythm(1 / 3)};
    }

    input,
    textarea {
      padding: ${rhythm(4 / 9)} ${rhythm(2 / 3)};
    }
  }
`;

// TODO: legacy Textarea code to re-evaluate
// let baseScrollHeight = 0

// function onInput(e) {
//   if (baseScrollHeight === 0) {
//     baseScrollHeight = e.target.scrollHeight
//   }
//   const minRows = e.target.getAttribute('data-min-rows')
//   e.target.rows = minRows
//   const rows = Math.ceil((e.target.scrollHeight - baseScrollHeight) / 21.3333)
//   e.target.rows = +minRows + rows
// }

const Input = (props) => {
  const {variant, labelText, ...otherProps} = props;

  return (
    <StyledInputBlock htmlFor={otherProps.id}>
      {labelText && <TextSmallCaps>{labelText}</TextSmallCaps>}
      {variant === 'textarea' ? (
        <textarea
          // onInput={onInput}
          rows={props.rows}
          data-min-rows="3"
          {...otherProps}
          placeholder={otherProps.placeholder}
        />
      ) : (
        <input {...otherProps} placeholder={otherProps.placeholder} />
      )}
    </StyledInputBlock>
  );
};

Input.propTypes = {
  labelText: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string,
  width: PropTypes.number,
  style: PropTypes.object,
  variant: PropTypes.oneOf(['default', 'textarea']),
  rows: PropTypes.number,
};

Input.defaultProps = {
  variant: 'default',
  labelText: '',
  id: '',
  style: {},
};

export default Input;
