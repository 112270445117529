// @flow
import PropTypes from 'prop-types';
import React from 'react';

import FormSubscribe from '../components/FormSubscribe';
import Layout from '../components/Layout';
import Markdown from '../components/Markdown';
import SectionSingleColumn from '../components/SectionSingleColumn';
import strings from '../config/strings.json';
import {LocationType} from '../types';

class Newsletter extends React.Component {
  render() {
    const {location} = this.props;
    const {pathname: path} = location;
    const {pageMeta, header, content} = strings.pages.newsletter;

    return (
      <Layout path={path} pageMeta={{strings: pageMeta}}>
        <SectionSingleColumn header={header}>
          <Markdown content={content} />
          <FormSubscribe
            inputList={{
              name: 'group[24169][2]',
              id: 'mce-group[24169]-24169-1',
            }}
          />
        </SectionSingleColumn>
      </Layout>
    );
  }
}

Newsletter.propTypes = {
  location: LocationType,
  data: PropTypes.object.isRequired,
};

export default Newsletter;
