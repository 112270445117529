// @flow
import React from 'react';
import styled from 'styled-components';

import {mediaQueries} from '../theme';
import {rhythm} from '../utils/typography';

import Link from './Link';

const StyledLabel = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  line-height: 1.2;
  margin-bottom: ${rhythm(1)};
  ${mediaQueries.MIN_LG} {
    margin-bottom: ${rhythm(1.5)};
  }
`;

export default class PrivacyCheckbox extends React.Component {
  render() {
    return (
      <StyledLabel htmlFor="privacy-checkbox">
        <input
          style={{marginRight: '0.5rem', position: 'relative', top: '4px'}}
          type="checkbox"
          name="privacy-checkbox"
          id="privacy-checkbox"
          required
        />
        <small style={{WebkitUserSelect: 'none'}}>
          I agree to Hanno’s storing and processing of my data in accordance
          with its{' '}
          <Link to="http://hanno.co/privacy" style={{whiteSpace: 'nowrap'}}>
            privacy policy
          </Link>
          .
        </small>
      </StyledLabel>
    );
  }
}
