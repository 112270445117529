// @flow
import React from 'react';
import styled from 'styled-components';

import {mediaQueries} from '../theme';
import dimensions from '../theme/dimensions';

import Button from './Button';
import Input from './Input';
import PrivacyCheckbox from './PrivacyCheckbox';

const StyledForm = styled.form`
  margin-bottom: 0;
  margin-top: ${dimensions.spacing.small};
  ${mediaQueries.MIN_MD} {
    margin-top: ${dimensions.spacing.default};
  }
`;

class FormSubscribe extends React.Component {
  render() {
    return (
      // We use the same subscribe form for both the Newsletter and for the Podcast, which is why the "action" and the honeypot field ID are the same throughout all usages
      <StyledForm
        action="https://hanno.us3.list-manage.com/subscribe/post?u=d6e1a629c92325ee35873e6bb&amp;id=d16d1ceab5"
        method="post"
      >
        <Input
          id="mce-EMAIL"
          name="EMAIL"
          placeholder="your@email.com"
          type="email"
          required
        />

        <div
          style={{
            position: 'absolute',
            left: '-5000px',
          }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_d6e1a629c92325ee35873e6bb_d16d1ceab5"
            tabIndex="-1"
          />
        </div>

        <PrivacyCheckbox />
        <Button type="submit">Subscribe</Button>
      </StyledForm>
    );
  }
}

export default FormSubscribe;
